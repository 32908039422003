import React, { useState } from "react";
import { Steps, Row, Skeleton } from "antd";
import { withTranslation } from "react-i18next";
import { useEffect } from "react";

function EventBar({ shipmentCode, eventsInfo }) {
  const [eventBarParameters, setEventBarParameters] = useState({
    current: 0,
    redirect: false,
    message: "Cargando...",
    status: "loading",
    statusCode: 0,
    track_num: shipmentCode,
    steps: [],
  });

  const { Step } = Steps;
  const redirectionUrl =
    "https://www.correos.es/es/es/herramientas/localizador/envios/detalle?tracking-number=";

  useEffect(() => {
    if (eventsInfo.statusCode === 0) {
      setEventBarParameters((prevState) => ({
        ...prevState,
        status: "loading",
        statusCode: 0,
        message: "Cargando...",
      }));
      return false;
    }

    if (eventsInfo.statusCode !== 200) {
      setEventBarParameters((prevState) => ({
        ...prevState,
        status: "loaded",
        statusCode: eventsInfo.statusCode,
        message: eventsInfo.message,
      }));
    }

    if (eventsInfo.statusCode === 200) {
      setEventBarParameters((prevState) => ({
        ...prevState,
        statusCode: eventsInfo.statusCode,
        steps: eventsInfo.steps,
        current: eventsInfo.current,
        status: "loaded",
        message: eventsInfo.message,
      }));
    }
  }, [eventsInfo]);

  return (
    <div className="Pasos">
      <Row>
        <h2>
          Situación actual del envío:{" "}
          <strong>{eventBarParameters.track_num}</strong>
        </h2>

        {eventBarParameters.status === "loading" && (
          <>
            <Skeleton active />
          </>
        )}

        {eventBarParameters.status === "loaded" &&
          eventBarParameters.statusCode === 200 && (
            <>
              <Steps current={eventsInfo.current}>
                {eventBarParameters.steps.map((item) => (
                  <Step
                    key={item.title}
                    title={item.title}
                    description={item.description}
                  />
                ))}
              </Steps>
              <div className="moreInfo">
                <a
                  href={`${redirectionUrl}${eventBarParameters.track_num}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>Mas información</span>{" "}
                  <svg
                    viewBox="64 64 896 896"
                    focusable="false"
                    data-icon="right"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"></path>
                  </svg>
                </a>
              </div>
            </>
          )}

        {eventBarParameters.status === "loaded" &&
          eventBarParameters.statusCode !== 200 && <></>}
      </Row>
    </div>
  );
}

export default withTranslation()(EventBar);
