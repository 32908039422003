import React from "react";
import { withTranslation } from "react-i18next";
import Alerts from "../Alerts/Alerts";
import AlertsList from "../AlertsList/AlertsList";
import ShipmentCharts from "../ShipmentCharts/ShipmentCharts";

function OnlineDataLoggerShipmentData({
  shipmentParameters,
  telemetryInfo,
  alertsListInfo,
  chartsInfo,
}) {
  return (
    <div>
      <h2>Tipo de datalogger: On-Line</h2>
      <Alerts
        shipmentCode={shipmentParameters.shipmentCode}
        telemetryInfo={telemetryInfo}
        hasTelemetry
      />
      <AlertsList
        shipmentCode={shipmentParameters.shipmentCode}
        alertsListInfo={alertsListInfo}
      />
      <ShipmentCharts
        shipmentCode={shipmentParameters.shipmentCode}
        chartsInfo={chartsInfo}
      />
    </div>
  );
}

export default withTranslation()(OnlineDataLoggerShipmentData);
